import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  register: {
    marginTop: 36,
    marginBottom: 36
  },
  right: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 16
    }
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={5} spacing={3} className={classes.right}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="overline" color="secondary">
                Introducing
              </Typography>
              <Typography variant="h1" color="textPrimary">
                GetOnChat - Capture leads from everywhere in the endless ways
              </Typography>
              <Box mt={3}>
                <Typography variant="body2" color="textSecondary">
                  Register a FREE account and get a Chat Handle. You can post
                  the handle on a website, or a blog, or send via email, your
                  readers can chat with you - instant text, voice and video chat
                  just click away...
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                className={classes.register}
              >
                <Button
                  color="secondary"
                  component={RouterLink}
                  to="/register"
                  variant="contained"
                  size="large"
                >
                  Create new account
                </Button>
              </Box>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography variant="h1" color="secondary">
                      Chat
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Mobile to Web
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="secondary">
                      Broadcast
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      All contacts
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="secondary">
                      AI
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Analytics
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* <Box
              display="flex"
              justifyContent="center"
              className={classes.register}
            >
              <Button
                color="secondary"
                component={RouterLink}
                to="/register"
                variant="contained"
                size="small"
              >
                Create new account
              </Button>
            </Box> */}
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <div className={classes.shape}>
                <img alt="Shapes" src="/static/home/shapes.svg" />
              </div>
              <div className={classes.image}>
                {/* <img alt="Presentation" src="/static/home/dark-light.png" /> */}
                <img alt="Presentation" src="/static/home/front.jpg" />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
