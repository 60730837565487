import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
  const { user } = useSelector(state => state.account);
  const location = useLocation();

  if (user) {
    if (location.pathname === '/login/chat') {
      return <Redirect to="/app/chat" />;
    }

    if (
      location.pathname.includes('/in/') ||
      location.pathname.includes('/@/') ||
      location.pathname.includes('/h/')
    ) {
      // return children;
      return <Redirect to={{
        pathname: `/app/chat/addagent${location.pathname}`, state: {from: `${location.pathname}`}
      }} />;
    }

    return <Redirect to="/app/chat" />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
