import axios from 'axios';

export const GET_CONTACTS = '@chat/get-contacts';
export const GET_CHATROOMS = '@chat/get-chatrooms';
export const GET_CHATROOM = '@chat/get-chatroom';
export const MARK_CHATROOM_AS_SEEN = '@chat/mark-chatroom-as-seen';
export const ADD_MESSAGE = '@chat/add-message';
export const OPEN_SIDEBAR = '@chat/open-sidebar';
export const CLOSE_SIDEBAR = '@chat/close-sidebar';
export const SET_CURRENT_CHATROOM = '@chat/set-current-chatroom';

export const GET_CHATROOM_MESSAGES = '@chat/get-chatroom-messages';
export const UPLOAD_FILES = '@chat/upload-files';
export const ADD_UNREADCOUNT = '@chat/add-readcount';
export const ADD_CHATROOM = '@chat/add-chatroom';
export const GET_ONLINE_USERS = '@chat/get_online_users';
export const ADD_ONLINE_USER = '@chat/add_online_user';
export const REMOVE_ONLINE_USER = '@chat/remove_online_user';
export const SET_SEARCH_TEXT = '@chat/set_search_text';
export const UPDATE_MESSAGE = '@chat/update-message';

export const getContacts = () => async dispatch => {
  try {
    const response = await axios.get('/api/contact/contacts');

    response.data &&
      dispatch({
        type: GET_CONTACTS,
        payload: response.data
      });
  } catch (error) {
    throw error;
  }
};

// export function getChatroom(chatroomId) {
//   //const request = axios1.get('/api/chat/chatroom', {
//   const request = axios1.get('/api/chat/thread', {
//     params: {
//       chatroomId
//     }
//   });

//   return dispatch => {
//     request.then(response => {
//       if (response.data.CHATROOM) {
//         dispatch({
//           type: GET_CHATROOM,
//           payload: response.data
//         });
//       }
//     });
//   };
// }

// export const markChatroomAsSeen = (chatroomId) => {
//   const request = axios.get(`/api/chat/mark-as-seen/{$(chatroomId)}`);
//   };

//   return (dispatch) => {
//     request.then(() => dispatch({
//       type: MARK_CHATROOM_AS_SEEN,
//       payload: {
//         chatroomId
//       }
//     }));
//   };
// }

export const markChatroomAsSeen = chatroomId => async dispatch => {
  try {
    const res = await axios.put('/api/chatroom/markChatroomAsSeen', {
      chatroomId
    });

    dispatch({
      type: MARK_CHATROOM_AS_SEEN,
      payload: {
        chatroomId
      }
    });
  } catch (error) {
    throw error;
  }
};

export const addUnreadCount = chatroomId => ({
  type: ADD_UNREADCOUNT,
  payload: {
    chatroomId
  }
});

export const addMessage = ({
  senderId,
  chatroomId,
  senderType,
  contentType,
  callType,
  startedAt,
  endedAt,
  body
}) => async dispatch => {
  try {
    // const res = await axios.post('/api/agent/message', {
    const res = await axios.post('/api/message', {
      senderId,
      chatroomId,
      senderType,
      contentType,
      body,
      callType,
      startedAt,
      endedAt
    });

    dispatch({
      type: ADD_MESSAGE,
      payload: {
        message: res.data.savedMessage,
        chatroomId
      }
    });

    return { savedMessage: res.data.savedMessage };
  } catch (error) {
    throw error;
  }
};

export const receiveMessage = ({
  receivedMessage: message,
  currentChatroomId,
  chatroomId
}) => async dispatch => {
  try {
    // const chatroomId = message.chatroom;
    if (currentChatroomId !== chatroomId) {
      await axios.put('/api/chatroom/unreadcount', { chatroomId });
    }

    dispatch({
      type: ADD_MESSAGE,
      payload: {
        message,
        chatroomId
      }
    });
  } catch (error) {
    throw error;
  }
};

export const updateMessage = ({
  messageId,
  chatroomId,
  body,
  stoppedBy,
  endedAt
}) => async dispatch => {
  try {
    // const res = await axios.post('/api/agent/message', {
    const res = await axios.put('/api/message', {
      messageId,
      body,
      stoppedBy,
      endedAt
    });

    dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        message: res.data.updatedMessage,
        chatroomId
      }
    });
    return { updatedMessage: res.data.updatedMessage };
  } catch (error) {
    throw error;
  }
};

export const addChatroom = ({ chatroom, contact }) => ({
  type: ADD_CHATROOM,
  payload: {
    chatroom,
    contact
  }
});

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

// export const getChatrooms = ({
//   limit = 400,
//   skip = 0,
//   sortBy = 'createdAt:acent'
// }) => async dispatch => {
//   try {
//     const res = await axios.get(
//       `/api/agent/chatrooms?limit=${limit}&skip=${skip}&sortBy=${sortBy}`
//     );
//     dispatch({
//       type: GET_CHATROOMS,
//       payload: res.data
//     });
//   } catch (error) {
//     throw error; // error = {error.response.data.message}
//   }
// };
export const getChatrooms = ({
  limit = 400,
  skip = 0,
  sortBy = 'createdAt:desc',
  appending = false
}) => async dispatch => {
  try {
    const res = await axios.get(
      `/api/agent/chatrooms?limit=${limit}&skip=${skip}&sortBy=${sortBy}`
    );
    dispatch({
      type: GET_CHATROOMS,
      payload: { data: res.data, appending }
    });

    return res.data.chatrooms;
  } catch (error) {
    throw error; // error = {error.response.data.message}
  }
};

export const getChatroomMessages = ({
  chatroomId,
  skip = 0,
  limit = 50,
  sortBy = 'createdAt:desc',
  appending
}) => async dispatch => {
  try {
    const res = await axios.get(
      `/api/message/chatroom/${chatroomId}/${skip}/${limit}/${sortBy}`
    );

    dispatch({
      type: GET_CHATROOM_MESSAGES,
      payload: {
        messages: res.data.messages,
        chatroomId,
        appending
      }
    });

    return { count: res.data.count };
  } catch (error) {
    throw error;
  }
};

export const setCurrentChatroom = chatroomId => dispatch => {
  // Store chatroomId in localstorage
  localStorage.setItem('chatroomId', chatroomId);

  dispatch({
    type: SET_CURRENT_CHATROOM,
    payload: chatroomId
  });
};

export const uploadFiles = (formData, chatroomId) => async dispatch => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const response = await axios.post('/api/message/upload', formData, config);
    if (response.data) {
      const { savedMessage } = response.data;

      // Preload images
      // savedMessage.attachments.forEach((image) => {
      //     const newImage = new Image();
      //     newImage.src = image.location;
      //     window[image] = newImage;
      // });

      dispatch({
        type: UPLOAD_FILES,
        payload: {
          message: savedMessage,
          chatroomId
        }
      });

      return response.data.savedMessage;
    } else {
      return response.data.error;
    }
  } catch (error) {
    throw error; // const {error} =  error.response.data;
    //  from express central error handler
  }
};

export const getOnlineUsers = users => ({
  type: GET_ONLINE_USERS,
  payload: {
    users
  }
});

export const addOnlineUser = user => ({
  type: ADD_ONLINE_USER,
  payload: {
    user
  }
});

export const removeOnlineUser = userId => ({
  type: REMOVE_ONLINE_USER,
  payload: {
    userId
  }
});

export const addAgent = ({ prospectId, handle, agentId }) => async dispatch => {
  try {
    // const res = await axios.post('/api/agent/message', {
    const res = await axios.post('/api/agent/addagent', {
      prospectId,
      handle,
      agentId
    });

    const { contact, chatroom } = res.data;

    if (res.data.status == 'new chatroom')
      dispatch(addChatroom({ contact, chatroom }));

    return { contact, chatroom };
  } catch (error) {
    throw error;
  }
};

export const setSearchText = searchText => ({
  type: SET_SEARCH_TEXT,
  payload: {
    searchText
  }
});
