import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import { LOGOUT } from 'src/actions/accountActions';

const appReducer = combineReducers({
  account: accountReducer,
  chat: chatReducer,
  notifications: notificationsReducer,
  mail: mailReducer,
  kanban: kanbanReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;

// https://www.digitalocean.com/community/tutorials/redux-reset-state-redux
// https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61
