import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { useHistory } from 'react-router';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Portal,
  Slide,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import VideoCallIcon from '@material-ui/icons/VideoCallOutlined';
import CallIcon from '@material-ui/icons/CallOutlined';
import CallEndIcon from '@material-ui/icons/CallEndOutlined';
import { Howl } from 'howler';

import { useSocket } from 'src/hooks/useSocket';
import ringtone from 'src/assets/sounds/ringtone.mp3';
import {
  addChatroom,
  receiveMessage,
  getOnlineUsers
} from 'src/actions/chatActions';
import { joinMasterChatroom, joinChatrooms } from '../utils/chatroom';

const ringtoneSound = new Howl({
  src: [ringtone],
  loop: true,
  preload: true
});

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 480,
    width: 400,
    position: 'fixed',
    top: 0,
    left: 0,
    margin: theme.spacing(4),
    outline: 'none',
    zIndex: 2000,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background
    // animation: '$progress 5s ease-out forwards'
  },
  // '@keyframes progress': {
  //   '0%, 49%': {
  //     backgroundColor: 'red'
  //     // border: '3px solid #e50000',
  //   },
  //   '50%, 100%': {
  //     backgroundColor: 'blue'
  //     // border: '3px solid RGB(117, 209, 63)'
  //   }
  // },
  topLine: {
    display: 'flex',
    flexDirection: 'row',
    aiignItems: 'start',
    justfyContent: 'space-around'
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: 'black'
  },
  endcallbutton: {
    backgroundColor: 'red',
    color: 'black'
  },
  notificationControl: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottome: theme.spacing(5)
  }
}));

function ChatHandler({ children }) {
  // Start of chat handling`
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { currentChatroomId, byKey } = useSelector(state => state.chat);
  const { user } = useSelector(state => state.account);
  const socket = useSocket();
  const [receivingCall, setReceivingCall] = useState(false);
  const [callerId, setCallerId] = useState('');
  const [callerSignal, setCallerSignal] = useState();
  const [chatroomId, setChatroomId] = useState();
  const [callType, setCallType] = useState('');
  const [isOpen, setOpen] = useState(false);
  const { byId: contactsIds } = useSelector(state => state.chat.contacts);

  // Listening to chat server for new messages
  useEffect(() => {
    socket.on('message', ({ message, chatroomId }) => {
      console.log('received msg', message.body);
      dispatch(
        receiveMessage({
          receivedMessage: message,
          currentChatroomId,
          chatroomId
        })
      );
    });
    return () => {
      // Unregister the listener, conresponding to 'on'
      // To remove an event listener
      // To prevent multiple connections with multiple sending msgs
      socket.off('message');
    };
  }, []);

  // Listening to and handling socket.io for the event of new chatroom
  useEffect(() => {
    socket.on('newChatroom', ({ chatroom, contact }) => {
      // console.log('newChatroom: contact', contact);
      // console.log('newChatroom: chatroom', chatroom);
      if (chatroom) {
        dispatch(addChatroom({ chatroom, contact }));
        console.log(
          'newChatroom from chathandle: chatroom, contact',
          chatroom,
          contact
        );

        // Join the newly created chatroom
        socket.emit(
          'join',
          { chatroomId: chatroom._id, userId: user._id },
          error => {
            if (error) {
              alert(error);
            }
            console.log('join chatroom id ', chatroom._id);
          }
        );
      }

      // alert
      enqueueSnackbar('New user is coming!', {
        variant: 'success'
        // persist: true,
      });
    });

    // video call
    socket.on('allUsers', users => {
      dispatch(getOnlineUsers(users));
    });

    socket.on('hey', data => {
      setReceivingCall(true);

      setCallerId(data.callerId);
      setChatroomId(data.chatroomId);
      setCallType(data.callType);
      setCallerSignal(data.signal);

      setOpen(true);
      ringtoneSound.play();
    });

    // caller ends the calling
    socket.on('dropcalling', () => {
      setReceivingCall(false);

      // setCalleeId('');
      // setCallerId('');
      // setCallerName('');
      // setChatroomId('');
      // setCallType('');
      // setCallerSignal(null);

      setOpen(false);
      ringtoneSound.stop();
    });
  }, []);

  socket.on('disconnect', reason => {
    console.log('disconnect reason', reason);
    if (reason === 'io server disco, reasonnnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect();
    }
    // else the socket will automatically try to reconnect
  });

  socket.on('reconnect', attempt => {
    if (!byKey) return;
    console.log('reconnect attempt', attempt);
    // Join master chatroom and chatrooms
    joinMasterChatroom(user._id, socket);
    const chatroomArray = Object.keys(byKey);
    joinChatrooms(chatroomArray, socket);
  });

  // Notistack action button
  const notistackRef = useRef(null);
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  // End of chat handling

  // Video call handling
  const AnswerCall = callType => {
    ringtoneSound.stop();

    // history.push({
    //   pathname: `/app/VideoCall/called/${
    //     callType === 'video' ? 'video' : 'voice'
    //   }/${callerId}/${calleeId}/${callerName}/${chatroomId}`,
    //   state: { callerSignal }
    // });
    history.push({
      pathname: `/app/called/${
        callType === 'video' ? 'video' : 'voice'
      }/${callerId}/${chatroomId}`,
      state: { callerSignal }
    });

    setOpen(false);
  };

  function rejectCall() {
    ringtoneSound.stop();
    socket.emit('rejected', { to: callerId, calleeId: user._id, chatroomId });
    // window.location.reload();
    setOpen(false);
  }

  let VideoCallNotificationPanel = '';
  if (isOpen) {
    VideoCallNotificationPanel = (
      <Portal>
        <Slide direction="down" in timeout={{ enter: 1000, exit: 2000 }}>
          <Paper className={classes.root} elevation={4}>
            <Box className={classes.topLine}>
              <Avatar
                src={contactsIds[callerId].avatar}
                size="medium}"
                style={{ marginRight: 16, marginBottom: 16 }}
              />
              <Typography variant="h4" color="textPrimary" gutterBottom>
                {contactsIds[callerId].fullName} is calling you...{' '}
              </Typography>
            </Box>
            <Box mt={2} className={classes.notificationControl}>
              {callType === 'video' && (
                <Tooltip title="Video Call">
                  <IconButton
                    onClick={() => AnswerCall('video')}
                    className={classes.button}
                  >
                    <VideoCallIcon />
                  </IconButton>
                </Tooltip>
              )}

              {callType === 'voice' && (
                <Tooltip title="Voice Call">
                  <IconButton
                    onClick={() => AnswerCall('voice')}
                    className={classes.button}
                  >
                    <CallIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="End Call">
                <IconButton
                  onClick={rejectCall}
                  className={classes.endcallbutton}
                >
                  <CallEndIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        </Slide>
      </Portal>
    );
  }

  return (
    <>
      {children}
      {VideoCallNotificationPanel}
    </>
  );
}

ChatHandler.propTypes = {
  children: PropTypes.any
};

export default ChatHandler;
