/* eslint-disable no-param-reassign */
import { Work } from '@material-ui/icons';
import produce from 'immer';
import _, { reduceRight } from 'lodash';
import {
  GET_CONTACTS,
  GET_CHATROOMS,
  GET_CHATROOM,
  MARK_CHATROOM_AS_SEEN,
  ADD_MESSAGE,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  SET_CURRENT_CHATROOM,
  GET_CHATROOM_MESSAGES,
  UPLOAD_FILES,
  ADD_CHATROOM,
  ADD_ONLINE_USER,
  REMOVE_ONLINE_USER,
  GET_ONLINE_USERS,
  SET_SEARCH_TEXT,
  UPDATE_MESSAGE
} from 'src/actions/chatActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  contacts: {
    byId: {}
  },
  chatrooms: {
    byKey: {}
  },
  currentChatroomId: null,
  totalUnreadCount: 0,
  sidebarOpen: false,
  onlineUsers: [], // {id: userId, socketId},
  searchText: ''
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS: {
      const { contacts } = action.payload;

      return produce(state, draft => {
        draft.contacts.byId = objFromArray(contacts, '_id');
      });
    }

    // case GET_CHATROOMS: {
    //   const { chatrooms } = action.payload;
    //   const sum = chatrooms.reduce((accu, chatroom) => accu + chatroom.unreadCount, 0);

    //   return produce(state, (draft) => {
    //     draft.chatrooms.byKey = objFromArray(chatrooms, '_id');
    //     draft.totalUnreadCount = sum;
    //   });
    // }
    case GET_CHATROOMS: {
      const {
        data: { chatrooms },
        appending
      } = action.payload;
      const sum = chatrooms.reduce(
        (accu, chatroom) => accu + chatroom.unreadCount,
        0
      );

      return produce(state, draft => {
        if (appending) {
          const newClassrooms = objFromArray(chatrooms, '_id');
          const combinedchatrooms = _.merge(
            {},
            draft.chatrooms.byKey,
            newClassrooms
          );
          draft.chatrooms.byKey = combinedchatrooms;
        } else {
          draft.chatrooms.byKey = objFromArray(chatrooms, '_id');
        }
        draft.totalUnreadCount = sum;
      });
    }

    // case GET_CHATROOM: {
    //   const { chatroom } = action.payload;

    //   return produce(state, (draft) => {
    //     draft.chatrooms.byKey[chatroom._id] = chatroom;
    //     }
    //   });
    // }

    case GET_CHATROOM_MESSAGES: {
      const { messages, chatroomId, appending } = action.payload;

      const reversedMessages = messages.reverse();
      return produce(state, draft => {
        if (appending) {
          draft.chatrooms.byKey[chatroomId].messages = reversedMessages.concat(
            draft.chatrooms.byKey[chatroomId].messages
          );
        } else {
          const chatroom = draft.chatrooms.byKey[chatroomId];
          // console.log('get chatroom messages: chatroomId,chatrooms.byKey[chatroomId]', chatroomId, chatroom)
          chatroom &&
            (draft.chatrooms.byKey[chatroomId].messages = reversedMessages);
        }
      });
    }

    case MARK_CHATROOM_AS_SEEN: {
      const { chatroomId } = action.payload;

      return produce(state, draft => {
        const chatroom = draft.chatrooms.byKey[chatroomId];

        // draft.totalUnreadCount -= chatroom.unreadCount;
        draft.totalUnreadCount -= chatroom.unreadCount;
        if (draft.totalUnreadCount < 0) draft.totalUnreadCount = 0;
        if (chatroom) {
          chatroom.unreadCount = 0;
        }
        draft.chatrooms.byKey[chatroomId] = chatroom;
      });
    }

    case ADD_MESSAGE: {
      const { message, chatroomId } = action.payload;

      return produce(state, draft => {
        let chatroom = draft.chatrooms.byKey[chatroomId];

        // if (!chatroom) {
        //   chatroom = {
        //     _id: chatroomId,
        //     messages: [message],
        //     participantIds: [otherUserId, userId],
        //     unreadCount: 0
        //   };

        //   draft.chatrooms.byKey[chatroomId] = chatroom;
        // } else {
        //   chatroom.messages.push(message);
        // }

        // if the message is from server and the message is not the current thread
        if (chatroomId !== state.currentChatroomId) {
          chatroom.unreadCount++;
          draft.totalUnreadCount++;
        }
        // chatroom.messages.push(message);
        // draft.chatrooms.byKey[message.chatroom] = chatroom;
        draft.chatrooms.byKey[chatroomId].messages.push(message);
      });
    }

    case UPDATE_MESSAGE: {
      const { message, chatroomId } = action.payload;

      return produce(state, draft => {
        const chatroom = draft.chatrooms.byKey[chatroomId];

        const index = chatroom.messages.findIndex(m => m._id === message._id);

        draft.chatrooms.byKey[chatroomId].messages[index] = message;
      });
    }

    case OPEN_SIDEBAR: {
      return produce(state, draft => {
        draft.sidebarOpen = true;
      });
    }

    case CLOSE_SIDEBAR: {
      return produce(state, draft => {
        draft.sidebarOpen = false;
      });
    }

    case SET_CURRENT_CHATROOM: {
      return produce(state, draft => {
        draft.currentChatroomId = action.payload;
      });
    }

    case UPLOAD_FILES: {
      const { chatroomId, message } = action.payload;

      return produce(state, draft => {
        // let chatroom = draft.chatrooms.byKey[chatroomId];
        // chatroom.messages.push(message);
        // draft.chatrooms.byKey[chatroomId] = chatroom;
        draft.chatrooms.byKey[action.payload.chatroomId].messages.push(
          action.payload.message
        );
      });
    }

    case ADD_CHATROOM: {
      const { chatroom, contact } = action.payload;

      return produce(state, draft => {
        // draft.chatrooms.byKey[chatroom._id] = chatroom;
        draft.chatrooms.byKey = {
          [chatroom._id]: chatroom,
          ...draft.chatrooms.byKey
        };
        // draft.contacts.byId[contact._id] = contact;
        draft.contacts.byId = {
          [contact._id]: contact,
          ...draft.contacts.byId
        };
      });
    }

    case GET_ONLINE_USERS: {
      const { users } = action.payload;

      return produce(state, draft => {
        draft.onlineUsers = users;
      });
    }

    case ADD_ONLINE_USER: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.onlineUsers = draft.onlineUsers.push(user);
      });
    }

    case REMOVE_ONLINE_USER: {
      const { userId } = action.payload;

      return produce(state, draft => {
        const index = draft.onlineUsers.findIndex(user => user.id === userId);

        if (index !== -1) {
          draft.onlineUsers = draft.onlineUsers.splice(index, 1)[0];
        }
      });
    }

    case SET_SEARCH_TEXT: {
      const { searchText } = action.payload;
      return produce(state, draft => {
        draft.searchText = searchText;
      });
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;

// When use searchWords in reducer as Array, it never Work, so changed to searchTexts 6/2022
