import axios from 'axios';
import jwtDecode from 'jwt-decode';
//import axios from 'src/utils/axios';
import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PASSWORD = '@account/update-password';
export const UPDATE_AVATAR = '@account/update-avatar';
export const UPDATE_LASTACTIVITY = '@account/update-lastactivity';
export const UPDATE_HANDLE = '@account/update-handle';

export const login = (email, password, from = 'AGENT') => async dispatch => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const { user } =
      from === 'AGENT'
        ? await authService.loginWithEmailAndPassword(email, password)
        : await authService.propsectLogin(email, password);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user
      }
    });

    return user;
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    throw error;
  }
};

export const prospectIn = ({
  email,
  password,
  fullName,
  agentId,
  handle
}) => async dispatch => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const {
      user,
      accessToken,
      chatroom,
      status
    } = await authService.prospectIn({
      email,
      password,
      fullName,
      agentId,
      handle
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user
      }
    });

    return { chatroom, user, accessToken, status };
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    throw error;
  }
};

export const oAuthLogin = accessToken => async dispatch => {
  try {
    // Check the valididty of the accessToken
    const decode = jwtDecode(accessToken);
    if (!decode) {
      throw new Error('jwt is not valid');
    }

    dispatch({ type: LOGIN_REQUEST });
    authService.setSession(accessToken);
    const user = await authService.loginInWithToken();
    const role = decode.user.role;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user,
        role
      }
    });
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    throw error;
  }
};

// export const setUserData = user => dispatch => {
//   dispatch({
//     type: SILENT_LOGIN,
//     payload: {
//       user
//     }
//   });
// };
export const setUserData = user => ({
  type: SILENT_LOGIN,
  payload: {
    user
  }
});

export const logout = (email) => async dispatch => {
  authService.logout(email);
  dispatch({
    type: LOGOUT
  });
};

export const register = userData => async dispatch => {
  try {
    const response = await axios.post('/api/agent/signup', userData);
    if (response.data.user) {
      return response.data.user;
    } else {
      return response.data.error;
    }
  } catch (error) {
    throw error; // error = {error.response.data.message}
  }

  /* promise version
  axios.post('/api/agent/signin', useData)
  .then((response) => {
    if (response.data.user) {
      resolve(response.data.user);
    } else {
      reject(response.data.error);
    }
  })
  .catch((error) => {
    reject(error);
  });
*/
};

export const updateProfile = values => async dispatch => {
  try {
    const res = await axios.put('/api/agent/profile', values);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });
  } catch (error) {
    console.log(
      'update profile error data = payload',
      error.response.data.message
    );
    throw error;
  }
};

// values = userData = {password, passwordConfirm}
export const updatePassword = userData => async dispatch => {
  try {
    console.log('update password', userData);
    const res = await axios.put('/api/agent/password', userData);
    dispatch({
      type: UPDATE_PASSWORD,
      payload: res.data
    });
  } catch (err) {
    console.log('change password err data = payload', err.response.data.errors);
  }
};

// values = userData = {password, passwordConfirm}
export const updateAvatar = formData => async dispatch => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const res = await axios.post('/api/account/s3/avatar', formData, config);
    dispatch({
      type: UPDATE_AVATAR,
      payload: res.data
    });

    return res.data.avatar;
  } catch (err) {
    console.log('update avatar err data = payload', err.response.data.errors);
  }
};

export const updateLastActivity = activity => async dispatch => {
  try {
    const res = await axios.post('/api/activity', activity);
    dispatch({
      type: UPDATE_LASTACTIVITY,
      payload: res.data
    });
  } catch (error) {
    console.log(
      'update profile error data = payload',
      error.response.data.message
    );
    throw error;
  }
};

// values = userData = {handle}
export const updateHandle = userData => async dispatch => {
  try {
    console.log('update handle', userData);
    const res = await axios.put('/api/agent/handle', userData);
    dispatch({
      type: UPDATE_HANDLE,
      payload: res.data
    });
  } catch (error) {
    throw error
    console.log('change password err data = payload', error.response.data.errors);
  }
};
