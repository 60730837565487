import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import {
  Mail as MailIcon,
  Link2 as HandleIcon,
  Phone as PhoneIcon
} from 'react-feather';
import { THEMES } from 'src/theme/config';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  mail: {
    // backgroundColor: colors.red[600]
    backgroundColor: theme.palette.secondary.main
  },
  button: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    },
    textTransform: 'none'
  }
}));

function ContactUs() {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const supportHandle = process.env.REACT_APP_BASEURL + '/@/support';

  return (
    <>
      <Button
        size="small"
        className={classes.button}
        variant="body2"
        color="textSecondary"
        // color={theme.name === THEMES.LIGHT? 'primary' : 'inherit'}
        onClick={handleOpen}
        ref={ref}
      >
        Contact
      </Button>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          Contact Us
        </Typography>
        <Box mt={1}>
          <List disablePadding>
            <ListItem>
              <ListItemIcon>
                <HandleIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="textPrimary">
                    <Link
                      variant="subtitle2"
                      color="textPrimary"
                      href={supportHandle}
                      target="_blank"
                    >
                      {supportHandle}
                    </Link>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="textPrimary">
                    <Link
                      variant="subtitle2"
                      color="textPrimary"
                      href="mailto:"
                      target="_top"
                    >
                      support@getonchat.com
                    </Link>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="textPrimary">
                    <Link
                      variant="subtitle2"
                      color="textPrimary"
                      href="tel:"
                      target="_top"
                    >
                      (408)673 7068
                    </Link>
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default ContactUs;
