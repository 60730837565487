import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // borderRadius: '10%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
    // width: 150,
    // height: 42,
    // backgroundSize: 150,
    // background: 'url(/static/logo.svg) 0 0',
  }
}));

const Logo = props => {
  const classes = useStyles();
  const { withText = true } = props;

  return withText ? (
    <img
      className={classes.root}
      alt="Logo"
      src="/static/logo.svg"
      width="160"
      height="44"
      // src="/static/1tran.gif"
      // width="1"
      // height="1"
      {...props}
    />
  ) : (
    <img
      className={classes.root}
      alt="Logo"
      src="/static/logo.png"
      width="44"
      height="44"
      {...props}
    />
  );
};

export default Logo;
