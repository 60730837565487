import React, { useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Link2 as HandleIcon } from 'react-feather';
import CopyHandle from 'src/components/CopyHandle';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 564,
    padding: theme.spacing(2)
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

function Handle() {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Chat Handle">
        <IconButton
          color="inherit"
          // color={theme.name === THEMES.LIGHT? 'primary' : 'inherit'}
          ref={ref}
          onClick={handleOpen}
        >
          <SvgIcon>
            <HandleIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h5" color="textPrimary">
          Chat Handle
        </Typography>
        <Box p={2}>
          <CopyHandle />
        </Box>
      </Popover>
    </>
  );
}

export default Handle;
