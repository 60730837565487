import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  List,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Users as UsersIcon } from 'react-feather';
import { getContacts } from 'src/actions/chatActions';
import { THEMES } from 'src/theme/config';
import { chatroomSelector } from 'src/utils/chatroom';
import ContactItem from './ContactItem';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 3)
  },
}));

function Contacts() {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { contacts, chatrooms } = useSelector(state => state.chat);
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();
  const { user } = useSelector(state => state.account);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  return (
    <>
      <Tooltip title="Contacts">
        <IconButton
          color="inherit"
          // color={theme.name === THEMES.LIGHT? 'primary' : 'inherit'}
          onClick={handleOpen}
          ref={ref}
        >
          <SvgIcon fontSize="small">
            <UsersIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          Contacts
        </Typography>
        <Box mt={2}>
          <List disablePadding>
            {Object.keys(contacts.byId)
              .reverse()
              .map(contactId => {
                const contact = contacts.byId[contactId];
                const chatroomId = chatroomSelector(chatrooms, user, contactId);

                return (<ContactItem contact={contact} chatroomId={chatroomId} />)
              })}
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default Contacts;
