import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

import ChatLayout from 'src/layouts/ChatLayout';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: [
      '/in/:agentId',
      '/h/:handle',
      '/@/:handle'
    ],
    component: lazy(() => import('src/views/auth/InView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ['/login', '/login/:forwardTo', '/login/email/:email'],
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/oauth/login/:accessToken',
    component: lazy(() => import('src/views/auth/OAuthLogin'))
  },
  {
    exact: true,
    path: '/oauth/google',
    component: () => {
      // window.location = 'http://localhost:4000/api/oauth/google';
      window.location = process.env.REACT_APP_API + '/api/oauth/google';
      return null;
    }
  },
  {
    exact: true,
    path: '/oauth/google/prospect',
    component: () => {
      window.location = process.env.REACT_APP_API + '/api/oauth/google/prospect';
      return null;
    }
  },
  {
    exact: true,
    path: '/oauth/facebook',
    component: () => {
      window.location = process.env.REACT_APP_API + '/api/oauth/facebook';
      return null;
    }
  },
  {
    exact: true,
    path: '/oauth/facebook/prospect',
    component: () => {
      window.location = process.env.REACT_APP_API + '/api/oauth/facebook/prospect';
      return null;
    }
  },
  {
    exact: true,
    path: '/oauth/microsoft',
    component: () => {
      window.location = process.env.REACT_APP_API + '/api/oauth/microsoft';
      return null;
    }
  },
  {
    exact: true,
    path: '/oauth/microsoft/prospect',
    component: () => {
      window.location = process.env.REACT_APP_API + '/api/oauth/microsoft/prospect';
      return null;
    }
  },
  {
    exact: true,
    path: '/oauth/linkedin',
    component: () => {
      // window.location = 'http://localhost:4000/api/oauth/linkedin';
      window.location = process.env.REACT_APP_API + '/api/oauth/linkedin';
      return null;
    }
  },
  {
    exact: true,
    path: '/oauth/twitter/prospect',
    component: () => {
      window.location = process.env.REACT_APP_API + '/api/oauth/twitter/prospect';
      return null;
    }
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/ForgotView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset/:resetToken',
    component: lazy(() => import('src/views/auth/ResetView'))
  },
  // {
  //   exact: true,
  //   path: '/tos',
  //   component: lazy(() => import('src/views/legal/ToSView'))
  // },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:chatroomId'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: [
          '/app/videocall/:callAction/:callType/:callerId/:calleeId/:callerName/:chatroomId'
          // callAction: calling or called;
          // callType: video -> video call, voice => voice call
        ],
        component: lazy(() => import('src/views/chat/VideoCallView'))
      },
      {
        exact: true,
        path: [
          '/app/calling/:callType/:calleeId/:chatroomId'
          // callAction: calling or called;
          // callType: video -> video call, voice => voice call
        ],
        component: lazy(() => import('src/views/chat/VideoCallView/CallingView'))
      },
      {
        exact: true,
        path: [
          '/app/called/:callType/:callerId/:chatroomId'
          // callAction: calling or called;
          // callType: video -> video call, voice => voice call
        ],
        component: lazy(() => import('src/views/chat/VideoCallView/CalledView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/chat/addagent/in/',
          '/app/chat/addagent/h/',
          '/app/chat/addagent/@/',
          '/app/chat/addagent/in/:agentId',
          '/app/chat/addagent/h/:handle',
          '/app/chat/addagent/@/:handle',
        ],
        component: lazy(() => import('src/views/auth/InView/AddAgent'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/chat',
    // guard: AuthGuard,
    layout: ChatLayout,
    routes: [
      {
        exact: true,
        path: '/chat',
        component: () => <Redirect to="/chat/new" />
      },
      {
        exact: true,
        path: ['/chat/new', '/chat/:chatroomId'],
        component: lazy(() => import('src/views/chat/ChatView'))
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routesConfig;
