import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Typography,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { Copy as CopyIcon } from 'react-feather';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  chatlink: {
    // width: isMobile ? 308 : 424
    width: isMobile ? 308 : 472
    // padding: 2,
    // fontSize:14,
    // height: 40,
  },
  icon: {
    marginLeft: theme.spacing(1),
  }
}));

function CopyText({ text, className, ...rest }) {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  const copyToClipboard = e => {
    // textAreaRef.current.select();
    textAreaRef.current.childNodes[0].childNodes[0].select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!');
    setInterval(() => {
      setCopySuccess('');
    }, 3000);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <form>
        <TextField
          // fullWidth
          className={classes.chatlink}
          ref={textAreaRef}
          value={text}
          variant="outlined"
          size="small"
          multiline
          rowsMax={2}
        />
      </form>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {copySuccess && (
          <Typography color="textPrimary" variant="body1">
            Copied
          </Typography>
        )}
        {!copySuccess && document.queryCommandSupported('copy') && (
          <Tooltip title="Copy to Clipboard" placement="right">
            <IconButton className={classes.icon} onClick={copyToClipboard}>
              <SvgIcon fontSize="inherit">
                <CopyIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

CopyText.propTypes = {
  className: PropTypes.string
};

export default CopyText;

// ref: https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
