import React, { useCallback } from 'react';
import s from 'src/utils/socket';

export const useSocket = () => {
  const socket = s().getSocket();
  return socket;
};

// export const useSocket = useCallback(() => {
//   const socket = s().getSocket();
//   return socket;
// }, []);
