import React, { useRef } from 'react';
// import { capitalCase } from 'change-case';
import {
  Badge,
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import {
  Link as RouterLink,
} from 'react-router-dom';
import {
  MessageCircle as MessageCircleIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 15,
    width: 15,
    borderRadius: 15,
    marginTop: 10,
    marginRight: 5,
    paddingBottom: 2
  }
}));

function Chats() {
  const classes = useStyles();
  const ref = useRef(null);

  return (
    <>
      <Tooltip title="Chats">
        <Badge
          badgeContent={22}
          color="error"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            component={RouterLink}
            to={'/app/chat'}
            color={'inherit'}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <MessageCircleIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
    </>
  );
}

export default Chats;
