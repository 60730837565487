import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CopyText from 'src/components/CopyText';

const useStyles = makeStyles(theme => ({
  note: {
    // marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3)
  }
}));

function CopyHandle({ className, ...rest }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);

  const handle = user.handle
    ? // ? process.env.REACT_APP_BASEURL + `/h/${user.handle}`
      process.env.REACT_APP_BASEURL + `/@/${user.handle}`
    : process.env.REACT_APP_BASEURL + `/in/${user._id}`;

  return (
    <Box
      className={className}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      justifyContent="flex-start"
      // textAlign="center"
    >
      <CopyText text={handle} />
      <Typography
        className={classes.note}
        color="textSecondary"
        variant="body2"
      >
        Share this link in email, blog, Wechat, WhatsApp, Facebook, etc., and
        allow people to chat with you via GetOnChat right away.
      </Typography>
    </Box>
  );
}

CopyHandle.propTypes = {
  className: PropTypes.string
};

export default CopyHandle;
