import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { isBrowser, isMobile, BrowserView } from 'react-device-detect';

import Logo from 'src/components/Logo';
import { THEMES } from 'src/theme/config';
import Account from './Account';
import Contacts from './Contacts/';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import Chats from './Chats';
import Handle from './Handle';
import { useRole } from '../../../hooks/useRole';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
          // backgroundColor: theme.palette.background.default
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 48
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const role = useRole();
  // const role = useSelector(state => state.account.user.roles);

  const dispTopLeftMenu = () => {
    if (role === 'AGENT') {
      return (
        <>
          <IconButton
            className={classes.menuButton}
            color={'inherit'}
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <RouterLink to="/" className={classes.logo}>
            <Logo withText={isMobile ? false : true} />
          </RouterLink>
        </>
      );
    } else {
      return (
        <RouterLink to="/" className={classes.logo}>
          <Logo withText={isMobile ? false : true} />
        </RouterLink>
      );
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {/* <Hidden lgUp>
        <IconButton
            className={classes.menuButton}
            color={'inherit'}
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden> */}
        {dispTopLeftMenu()}
        <Box ml={2} flexGrow={1} />
        {role === 'AGENT' && <Handle />}
        {/* <Search /> */}
        <Chats />
        <Contacts />
        {/* <Notifications /> */}
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
