import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Plus as PlusIcon,
  Home as HomeIcon,
  Mail as MailIcon,
  Briefcase as BriefcaseIcon,
  Link2 as HandleIcon
} from 'react-feather';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  },
  jobAvatar: {
    backgroundColor: theme.palette.secondary.main
  },
  cityAvatar: {
    backgroundColor: colors.red[600]
  },
  avatar: {
    border: `2px solid ${theme.palette.common.white}`,
    height: 80,
    width: 80,
    // top: -60,
    left: theme.spacing(3)
    // position: 'absolute'
  }
}));

export default function ContactCard({ anchorEl, isOpen, user }) {
  const classes = useStyles();

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box mt={3}>
          <Card>
            {/* <CardHeader title="About me" /> */}
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-end"
              mb={2}
            >
              <Avatar
                alt="Person"
                className={classes.avatar}
                src={user.avatar}
              />
              <Box ml={5} mb={2}>
                <Typography variant="overline" color="textSecondary">
                  {user.bio}
                </Typography>
                <Typography variant="h4" color="textPrimary">
                  {user.name}
                </Typography>
              </Box>
            </Box>

            <Divider />
            <CardContent>
              <Typography variant="subtitle2" color="textSecondary">
                &quot;
                {user.notes}
                &quot;
              </Typography>
              <List>
                {user.bio && (
                  <ListItem disableGutters divider>
                    <ListItemAvatar>
                      <Avatar className={classes.jobAvatar}>
                        <BriefcaseIcon size="22" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2" color="textPrimary">
                          {user.bio} at{' '}
                          <Link
                            variant="subtitle2"
                            color="textPrimary"
                            href="#"
                          >
                            {user.company}
                          </Link>
                        </Typography>
                      }
                      // secondary={
                      //   <Typography variant="caption" color="textSecondary">
                      //     Past:
                      //     {user.previousJob.title}{' '}
                      //     <Link variant="caption" color="textSecondary" href="#">
                      //       {user.previousJob.company}
                      //     </Link>
                      //   </Typography>
                      // }
                    />
                  </ListItem>
                )}
                <ListItem disableGutters divider>
                  <ListItemAvatar>
                    <Avatar className={classes.cityAvatar}>
                      <HandleIcon size="22" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2" color="textPrimary">
                        Share:{' '}
                        <Link variant="subtitle2" color="textPrimary" href="#">
                          {!!user.handle
                            ? // ? process.env.REACT_APP_BASEURL + `/h/${user.handle}`
                              process.env.REACT_APP_BASEURL +
                              `/@/${user.handle}`
                            : process.env.REACT_APP_BASEURL + `/in/${user._id}`}
                        </Link>
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" color="textSecondary">
                        Please share my getOnChat handle with your friends i am
                        ready to help.
                      </Typography>
                    }
                  />
                </ListItem>
                {!!user.city && (
                  <ListItem disableGutters divider>
                    <ListItemAvatar>
                      <Avatar className={classes.cityAvatar}>
                        <HomeIcon size="22" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2" color="textPrimary">
                          Lives in{' '}
                          <Link
                            variant="subtitle2"
                            color="textPrimary"
                            href="#"
                          >
                            {user.city}
                          </Link>
                        </Typography>
                      }
                      secondary={
                        <Typography variant="caption" color="textSecondary">
                          State{' '}
                          <Link
                            variant="caption"
                            color="textSecondary"
                            href="#"
                          >
                            {user.state}
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
                <ListItem disableGutters divider>
                  <ListItemAvatar>
                    <Avatar className={classes.cityAvatar}>
                      <MailIcon size="22" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.email}
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: 'textPrimary'
                    }}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Box>
      </Popover>
    </div>
  );
}
