import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Avatar,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Users as UsersIcon } from 'react-feather';
import OnlineIndicator from 'src/components/OnlineIndicator';
import ContactCard from 'src/components/ContactCard';

const useStyles = makeStyles(theme => ({
    listItemText: {
      marginRight: theme.spacing(1)
    },
    lastActivity: {
      whiteSpace: 'nowrap'
    }
  }));

const ContactItem = ({contact, chatroomId}) => {
  const classes = useStyles();
  const avatarRef = useRef(null);
  const [isContactCardOpen, setContactCardOpen] = useState(false);

  return (
    <ListItem button disableGutters key={contact._id}>
      <ListItemAvatar>
        <Avatar
          alt="Person"
          src={contact.avatar}
          ref={avatarRef}
          onMouseEnter={() => setContactCardOpen(true)}
          onMouseLeave={() => setContactCardOpen(false)}
        />
        {contact.roles === 'AGENT' && (
          <ContactCard
            anchorEl={avatarRef.current}
            isOpen={isContactCardOpen}
            user={contact}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        className={classes.listItemText}
        disableTypography
        primary={
          <Link
            color="textPrimary"
            component={RouterLink}
            display="block"
            underline="none"
            noWrap
            to={`/app/chat/${chatroomId}`}
            variant="h6"
          >
            {contact.fullName}
          </Link>
        }
      />
      {contact.isActive ? (
        <OnlineIndicator size="small" status="online" />
      ) : (
        <Typography color="textSecondary" noWrap variant="caption">
          {moment(contact.lastActivity).fromNow()}
        </Typography>
      )}
    </ListItem>
  );
};

export default ContactItem;
