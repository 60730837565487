import React, { useRef } from 'react';
// import { capitalCase } from 'change-case';
import {
  Badge,
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MessageCircle as MessageCircleIcon } from 'react-feather';
import { THEMES } from 'src/theme/config';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 15,
    width: 15,
    borderRadius: 15,
    marginTop: 10,
    marginRight: 5,
    paddingBottom: 2
  }
}));

function Chats() {
  const classes = useStyles();
  const ref = useRef(null);
  const { totalUnreadCount, currentChatroomId } = useSelector(
    state => state.chat
  );
  const theme = useTheme();

  return (
    <>
      <Tooltip title="Chats">
        <Badge
          badgeContent={totalUnreadCount}
          color="error"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            component={RouterLink}
            // to={`/app/chat/${currentChatroomId}`}
            to={`/app/chat/new`}
            color="inherit"
            // color={theme.name === THEMES.LIGHT? 'primary' : 'inherit'}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <MessageCircleIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
    </>
  );
}

export default Chats;
