import jwtDecode from 'jwt-decode';
import axios from 'axios';
//import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  // loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
  //   axios.post('/api/agent/signin', { email, password })
  //     .then((response) => {
  //       if (response.data.user) {
  //         this.setSession(response.data.accessToken);
  //         resolve(response.data);
  //       } else {
  //         reject(response.data.error);
  //       }
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // })

  // loginInWithToken = () => new Promise((resolve, reject) => {
  //   const accessToken = this.getAccessToken();
  //   const decoded = jwtDecode(accessToken);
  //   const userId = decoded.user.id; 

  //   console.log('loginwithtoken decoded.user.id;', decoded.user.id)
  //   axios.get(`/api/agent/id/${userId}`)
  //     .then((response) => {
  //       if (response.data.user) {
  //         resolve(response.data.user);
  //       } else {
  //         reject(response.data.error);
  //       }
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // })
  loginWithEmailAndPassword = async (email, password, from = 'AGENT') => {
    try {
      const response = await axios.post( 
        `/api/agent/${from === 'AGENT' ? 'signin' : 'psignin'}`, { email, password });

      if (response.data.user) {
        this.setSession(response.data.accessToken);
        return response.data;
      } else {
        return response.data.error;
      }
    } catch (error) {
      throw error;
    }
  }

  loginInWithToken = async () => {
    const accessToken = this.getAccessToken();
    const decoded = jwtDecode(accessToken);
    const userId = decoded.user.id; 
    try {
      const response = await axios.get(`/api/agent/id/${userId}`);
      if (response.data.user) {
        return response.data.user;
      } else {
        return response.data.error;
      }
    } catch (error) {
      throw error;
    }
  }

  /*
 loginInWithToken = async () => {
  try {
    const response = await axios.get('/agent/');
    if (response.data.user) {
      return (response.data.user);
    } else {
      return (response.data.error);
    }
  } catch (error) {
     return error;
  }
};
*/

  logout = async (email) => {
    // cross cuting: log
    // const response = await axios.post( 
      // 'api/activity/', { type: 'Logout', agent: userId });
    const response = await axios.put('api/agent/signout');

    this.setSession(null);
    this.setEmailToken(email);
    console.log('auth logout:email', email)
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  prospectIn= async ({email, password, fullName, agentId, handle}) => {
    try {
      let response;
      if(!handle){
        response = await axios.post( '/api/agent/prospectjoin', {
          email, password, fullName, agentId
        });
      } else {
        response = await axios.post( '/api/agent/prospectjoinviahandle', {
          email, password, fullName, handle
        });
      }

      if (response.data.user) {
        this.setSession(response.data.accessToken);
        return response.data;
      } else {
        return response.data.error;
      }
    } catch (error) {
      throw error;
    }
  }

  isAuthenticated = () => !!this.getAccessToken()

  setEmailToken = (email) => {
    if (email) {
      localStorage.setItem('emailToken', email);
    } else {
      localStorage.removeItem('emailToken');
    }
  }

  getEmailToken = () => localStorage.getItem('emailToken');
}

const authService = new AuthService();

export default authService;
