export const joinMasterChatroom = (user, socket) => {
    socket.emit(
      'join',
      {
        chatroomId: user._id,
        userId: user._id
      },
      error => {
        if (error) {
          alert(error);
        }
        console.log('master chatroom join: chatroom id ', user._id);
      }
    );
  };

  export const joinChatrooms = (chatroomArray, socket) => {
    socket.emit('joinInBatch', { chatroomArray }, error => {
      if (error) {
        alert(error);
      }
    });
  }

  // Find the chatroomsId from contacts via contactId
  export const chatroomSelector = (chatrooms, user, contactId) => {
    const chatroomArray = Object.values(chatrooms.byKey);
  
    if (!chatroomArray) return 'new';
    let chatroomId, matchedChatroom;
    if (user.roles === 'AGENT') {
      matchedChatroom = chatroomArray.find(
        chatroom => chatroom.prospect === contactId
      );
      if (!matchedChatroom) return 'new';
      chatroomId = matchedChatroom._id;
    } else {
      matchedChatroom = chatroomArray.find(
        chatroom => chatroom.agent === contactId
      );
      if (!matchedChatroom) return 'new';
      chatroomId = matchedChatroom._id;
    }
    return chatroomId;
  };